import React from "react";
import tiktokw from "../assets/img/tiktikw.png";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import WhatsapIcon from "../assets/img/whatsapp.png";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* <!-- Start Footer section    --> */}
      <footer id="footer-main">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-4">
              <div className="widget footer-widget">
                <h4>{t("Footer.Foot_Title1")}</h4>
                <ul className="list-inline coin-widget">
                  <li>
                    <a href="#">
                      <i className="fa  fa-cc-mastercard"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-cc-visa"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-cc-paypal"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa  fa-cc-discover"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa  fa-cc-amex"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa  fa-cc-stripe"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="widget news-widget">
                <h4>{t("Footer.Foot_Title2")}</h4>

                {/* <!-- Input Card --> */}
                <div className="input-card mt-3">
                  <div className="input-card-form newsletter">
                    <input
                      type="email"
                      className="form-control newsletter-input"
                      id="newsletter_email_footer"
                      name="email"
                      placeholder="Email Address"
                      aria-label="Email Address"
                      maxlength="99"
                    />
                  </div>
                  <button
                    type="submit"
                    name="submit"
                    value="form"
                    className="btn btn-warning newsletter-button"
                  >
                    Subscribe
                  </button>
                </div>
                {/* <!-- End Input Card --> */}
                <div id="form_newsletter_response"></div>
                <input type="hidden" name="sys_lang_id" value="1" />
                <ul>
                  <li>
                    <a href="#">&nbsp;</a>
                  </li>
                  <li>You can unsubscribe at any time</li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-sm-6">
              <div className="widget news-widget">
                <h4>{t("Footer.Foot_Title3")}</h4>

                <ul>
                  <li>
                    <a href="#">{t("Footer.Foot_Url1")}</a>
                  </li>
                  <li>
                    <NavLink to="/roadmap">{t("Footer.Foot_Url2")}</NavLink>
                  </li>
                  <li>
                    <a href="#">{t("Footer.Foot_Url3")}</a>
                  </li>
                  <li>
                    <NavLink to="/whitepaper-english">
                      {t("Footer.Foot_Url4")}
                    </NavLink>
                  </li>
                  <li>
                    <a href="#">{t("Footer.Foot_Url5")}</a>
                  </li>
                  <li>
                    <NavLink to="/privacy">{t("Footer.Foot_Url6")}</NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="widget contact-widget">
                <h4>{t("Footer.Foot_Title4")}</h4>

                <ul className="footer-contact">
                  <li>
                    <i className="fa fa-map-marker"></i>
                    {t("Footer.Foot_Address")}
                  </li>
                  <li>
                    <i className="fa fa-phone"></i>{" "}
                    <span>+44 7488 8808 02</span>
                  </li>
                  <li>
                    <i className="fa fa-envelope-o"></i>{" "}
                    <span>info@hvambit.com</span>
                  </li>
                </ul>

                <ul className="list-inline">
                  <li>
                    <a href="https://www.facebook.com/hvamBIT/" target="_blank">
                      <i className="icon ion-social-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/Hvambit" target="_blank">
                      <i className="icon ion-social-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/hvambit/"
                      target="_blank"
                    >
                      <i className="icon ion-social-instagram"></i>
                    </a>
                  </li>
                  {/* <li><a href="https://www.linkedin.com/in/hvam-coins-a58307173/" target="_blank" ><i className="icon ion-social-linkedin"></i></a></li> */}
                  <li>
                    <a href="http://www.tiktok.com/@hvambit" target="_blank">
                      <img
                        src={tiktokw}
                        alt=""
                        className="icon"
                        style={{ width: "10px", marginTop: "-3px" }}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 text-center">
              <p className="copy ">
                {t("Footer.Foot_Copyright1")} <span>HVAMBIT</span>{" "}
                {t("Footer.Foot_Copyright2")}
              </p>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- End Footer section    --> */}

      {/* <!-- Start Back To Top --> */}
      <a data-scroll id="back-to-top" href="#hero">
        <i className="icon ion-chevron-up"></i>
      </a>
      {/* <!-- End Back To Top --> */}
      <div class="ccw_plugin chatbot">
        <div class="style4 animated no-animation ccw-no-hover-an">
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=+44 7488 8808 02&text=مرحبا أنا اتواصل معكم من خلال الموقع"
            class="nofocus"
          >
            <div class="chip ccw-analytics" id="style-4" data-ccw="style-4">
              <img
                class="ccw-analytics"
                id="s4-icon"
                data-ccw="style-4"
                src={WhatsapIcon}
                alt="WhatsApp chat"
              />
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
