import React from "react";
import Header from "../Components/Header";
import logo from "../assets/images/logo.png";
import aboutus from "../assets/images/about-us-image.png";
import goalone from "../assets/images/goal-image-one.png";
import edges from "../assets/images/edges-image.png";
import stepone from "../assets/images/stepone.png";
import steptwo from "../assets/images/steptwo.png";
import stepthree from "../assets/images/stepthree.png";
import stepfour from "../assets/images/stepfour.png";
import stepfive from "../assets/images/stepfive.png";
import beyound from "../assets/images/lets-go-beyound-image.png";
import blockc from "../assets/images/blockchain-image.png";
import ecoservices from "../assets/images/eco-services.png";
import secone from "../assets/images/section10-image1.png";
import sectwo from "../assets/images/section10-image2.png";
import secthree from "../assets/images/section10-image3.png";
import secfour from "../assets/images/section10-image4.png";
import secfive from "../assets/images/flowchart-image.png";
import walletim from "../assets/images/wallet-image.png";
import swapim from "../assets/images/swap-image.png";
import plateformim from "../assets/images/plateform-image.png";
import projectim from "../assets/images/project-image.png";
import teamim from "../assets/images/team-image.png";
import customerim from "../assets/images/customer-image.png";
import innovationim from "../assets/images/innovation-image.png";
import opportunityim from "../assets/images/opportunity-image.png";
import milestoneim from "../assets/images/milestone-imge.png";
import legitimacyim from "../assets/images/legitimacy-image.png";
import creditim from "../assets/images/credit-image.png";
import facebookim from "../assets/images/facebook-icon.png";
import instaim from "../assets/images/insta-icon.png";
import tiktokim from "../assets/images/tiktok-icon.png";
import whatsappim from "../assets/images/whatsapp-icon.png";
import emailim from "../assets/images/email-icon.png";
import websiteim from "../assets/images/website-icon.png";
import lastim from "../assets/images/last-sec-right-img.png";
import magerodmap from "../assets/images/mage-rodmap.png";
import Footer from "../Components/Footer";

const WhitepaperArabic = () => {
  return (
    <>
      <Header />

      <section id="hero" className="pt80 bg-dark heromian"></section>
      <section className="">
        <div className="container">
          <div className="row">
            <div className="ol-12 text-center">
              <img src={logo} className="img-responsive m-auto" alt="" />
              <h1 className="logo-font">HVAMBIT</h1>
              <h2 className="sec1-second-text">رؤية جديدة للخدمات</h2>
              <h2 className="sec1-second-text">المصرفية المدعومة بتقنيۃ</h2>
              <h2 className="sec1-second-text">بلوكتشين</h2>
              <br />
              <p>اجعل تداولك أسهل وأسرع</p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 text-right">
              <h1 className="color-org">خلاصہ</h1>
            </div>
            <div className="col-12 col-md-6">
              <p>
                HVAM BIT هي رؤية جديدة للخدمات المصرفية مدعومة من قبل بلوكشين.
                تقدم هذه المنصة العديد من الفوائد لمستخدميها مثل التداول. سيعتمد
                مشروعنا بطريقة ما على بلوكشين في تنفيذ عمليات التأمين وإرسال
                التحويلات المالية بين أطراف المعاملات التجارية. وبالتالي ،
                سيحافظ النظام على بيانات العميل آمنة وسرية ، مما يضمن الاستلام
                والتسليم. يتم إنشاء تطبيقات عالية الجودة باستخدام عقد ذكي لإنشاء
                عمليات بيع وتسهيل المعاملات بين الأطراف
              </p>
            </div>
            <div className="col-12 col-md-6">
              <p>
                تلعب العملات المشفرة في هذه الأيام دورًا مفيدًا في الحياة
                اليومية. إنه أصل رقمي يمكن تدويره دون الحاجة إلى تمويل مركزي. لا
                مركزية ومشفرة. يستخدم التشفير لأمن المعاملات. يتم تسجيل كل
                معاملة عملة مشفرة في دفتر أستاذ عام يسمى بلوكشين وهي عمومًا
                عملية سريعة ومباشرة ، مما يعني أنه يمكن نقلها من محفظة رقمية إلى
                أخرى ، باستخدام هاتف ذكي أو كمبيوتر فقط.
              </p>
              <br />
              <br />
              <p>
                بلوكشين هي قاعدة بيانات رقمية ونظام لتسجيل المعلومات مصمم لجعل
                اختراق النظام وغزو السجلات (مثل المعاملات المالية والمعلومات
                الشخصية) أمرًا مستحيلًا. يسمح بتسجيل المعلومات الرقمية الضرورية
                وتوزيعها. وبالتالي ، فإن بلوكشين هي قاعدة الحافة الثابتة
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 text-center">
              <h1 className="sect-heading bottom color-org">معلومات عنا</h1>
              <p>
                تأسست عام 2021. HVAM BIT هي عبارة عن منصة الكل في واحد مدعومة من
                قبل بلوكشين. تمنح منصتنا للمشاركين مزايا ومزايا حصرية مثل محفظة
                التشفير الخاصة ، والتداول ، والمبادلة ، ومنصة NFT ، وخدمات ضمان
                الدفع المستندة إلى التشفير. حرص مطورو HVAM والفريق على تقييم
                ومراقبة كل التفاصيل والميزات الخاصة بهذا المشروع لتحقيق أعلى
                مستويات الأمان. يعالج مشروعنا إحدى المشكلات في السوق ككل ؛
                "الثقة" من خلال تطوير التطبيقات اللامركزية وخدمات الضمان. أفضل
                مخطط لدينا هو ضمان أمان كل معاملة وعملية ، وعالية السرعة ،
                وفعالية من حيث التكلفة. باختصار ، مشروعنا هو صناعة التجارة
                الآمنة بين الشركات والأفراد.
              </p>
              <img src={aboutus} className="img-responsive" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-3">
              <img src={goalone} alt="" className="img-responsive dblock" />
            </div>
            <div className="col-12 col-md-9">
              <h1 className="color-org">هدف</h1>
              <p>
                تم إنشاء وتطوير منصة تداول HVAM BIT لتوفير بيئة تداول أكثر
                أمانًا لتبادل العملات المشفرة والأصول القيمة مع الاستمرار في
                كونها لا مركزية. يتمثل أحد أغراضنا في استبدال الأدوات المالية
                الكلاسيكية (LC.DLC.SBLC) بأداة مالية قائمة على العملة المشفرة
                لجعل حركة التداول أسهل وأكثر أمانًا بين الأطراف والأفراد. نهدف
                إلى تقديم خدمة عالية الجودة وإتاحة فرص فريدة لكل مشارك.
              </p>
            </div>
            <div className="col-12 col-md-3">
              <img src={goalone} alt="" className="img-responsive dnone" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-9">
              <h1 className="sec4-right2-text color-org">حواف</h1>
              <p className="sec4-right2-text">
                تتم دراسة وتطوير جميع الخطوات بعناية: العملية والنهج. تتم ملاحظة
                كل عملية في هذا المشروع: التجارب والأخطاء التي أجريت. يتم الوصول
                إلى معايير الأرباح المتوقعة واكتساب ثقة العملاء والمستثمرين.
                معلومات المستخدم والأمن المالي والسرية والمعاملات الخالية من
                المتاعب هي الأولوية القصوى لمنصتنا. بالإضافة إلى ذلك ، سيتم دعم
                الشركة للمشروع بمبلغ 250 مليون دولار كتأمين ضد المخاطر.
              </p>
            </div>
            <div className="col-12 col-md-3">
              <img src={edges} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 text-center">
              <h1 className="sec5-top-head color-org bottom">وراء HVAMBIT</h1>
              <p>
                لا تركز منصة HVAM فقط على منتج واحد ، وبالتالي فهي تقدم العديد
                من الأشياء لكل مشارك مع ضمان الأمان العالي والرسوم الودية لكل
                معاملة.
              </p>
            </div>

            <div className="row pt50">
              <div className="col-12 col-md-1"></div>
              <div className="col-12 col-md-2 text-center">
                <img src={stepone} className="img-responsive m-auto" alt="" />
                <p className="sec5-step1-1 color-org">مشروع NFT</p>
                <p>وصول حصري إلى مجموعات NFT الفريدة الخاصة بنا.</p>
              </div>
              <div className="col-12 col-md-2 text-center">
                <img src={steptwo} className="img-responsive m-auto" alt="" />
                <p className="sec5-step1-1 color-org">منصة NFT</p>
                <p>إنشاء وبيع وشراء والإعلان عن مجموعات NFT الفريدة</p>
              </div>
              <div className="col-12 col-md-2 text-center">
                <img src={stepthree} className="img-responsive m-auto" alt="" />
                <p className="sec5-step1-1 color-org">مبادلة HVAM</p>
                <p>صرف العملات الرقمية إلى HVAM</p>
              </div>
              <div className="col-12 col-md-2 text-center">
                <img src={stepfour} className="img-responsive m-auto" alt="" />
                <p className="sec5-step1-1 color-org">محفظة HVAM</p>
                <p>قم بتخزين جميع أصولك في مكان واحد</p>
              </div>
              <div className="col-12 col-md-2 text-center">
                <img src={stepfive} className="img-responsive m-auto" alt="" />
                <p className="sec5-step1-1 color-org">خدمات الضمان HVAM</p>
                <p>
                  استخدم التشفير الخاص بك بأي طريقة وقم بحماية مدفوعاتك العالمية
                  باستخدام ضمان HVAM.
                </p>
              </div>
              <div className="col-12 col-md-1"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 text-center">
              <h1 className="color-org">لنذهب أبعد من ذلك</h1>
              <h2 className="bottom color-org">خدمة الضمان HVAM</h2>
              <p>
                <b>
                  استخدم التشفير الخاص بك بأي طريقة وقم بحماية مدفوعاتك العالمية
                  باستخدام ضمان HVAM.
                </b>
              </p>
              <p>
                حمي خدمة الضمان HVAM كلاً من البائع والمشتري في المعاملة. حافظ
                على أمان أموالك حتى يتم تسليم البضائع أو استلامها ، ورسوم
                المعاملات المعقولة ، واستعادة أموالك (باستثناء رسوم المعاملات)
                إذا لم تستوف الشروط.
              </p>
              <img src={beyound} className="img-responsive" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 text-center">
              <h2 className="bottom">دمة الضمان HVAM | بلوكشين- مقرها</h2>
              <p>
                تعمل خدمات الضمان اللامركزية على حل مشكلات الثقة في عالم
                الأعمال. على وجه التحديد ، في الطريقة التقليدية لتخزين بيانات
                العميل الشخصية والمالية في قاعدة بيانات مشفرة في مكان مركزي
                واحد. قد تحدث ظروف محتملة وستفقد جميع بياناتك المخزنة في موقع
                مركزي واحد في غمضة واحدة فقط بسبب نقص السجلات الأمنية. منذ ظهور
                العملات المشفرة ، شهدنا تطوير تقنية جديدة تساعد في بناء الثقة
                المطلقة دون الحاجة إلى وجود أطراف ثالثة في الطريق. هذه هي
                الطريقة التي تناسب بلوكشين واللامركزية. الضمان اللامركزي هو آلية
                مستقلة تقلل من الحاجة إلى التفاعلات البشرية ، وبدلاً من ذلك
                ستعمل جميع العمليات عبر التطبيقات الآلية التي يتم إنشاؤها داخل
                بلوكشين. بلوكشين هو حاضر ومستقبل جميع معاملات الضمان. بدلاً من
                الاعتماد على أطراف ثالثة تقليدية ، أصبح حل العقد الذكي وهو
                الضمان أسهل كثيرًا للجميع. تم تبسيط العملية لضمان استيفاء الشروط
                قبل تنفيذ المعاملات بأمان.
              </p>
            </div>
            <div className="col-12 col-md-4 text-center">
              <img src={blockc} className="img-responsive" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 text-center">
              <p className="center-text">
                تتبنى HVAM طريقة لامركزية في خدمات الضمان. ستعمل HVAM كطرف محايد
                في كل معاملة ، فهي تلغي الحاجة إلى وسيط أو وكيل ضمان ، مما يعني
                أنها ستقلل من التكاليف والوقت في تنفيذ المعاملة ، وسيكون
                المستخدمون جزءًا من المشروع من خلال استثمار أموالهم على المنصة .
                علاوة على ذلك ، سيتم تخزين الأموال / الأصول في حساب ضمان حتى يتم
                استيفاء الشروط. يحتاج كلا الطرفين المشتركين في الاتفاقية إلى
                ضمان تسليم كل من الخدمة المتفق عليها والدفع.
              </p>
              <img src={ecoservices} className="img-responsive" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <h1 className="bottom">معايير مضمونة:</h1>
              <p className="bottom">
                <b>الثقة والأمان</b> – الضمان الخاص بالعقود الذكية هو ببساطة
                برامج مخزنة على بلوكشين يتم تشغيلها عند استيفاء شروط محددة
                مسبقًا. وبالتالي ، فإنه يقلل من مخاطر الاحتيال عن طريق البدء
                كطرف محايد وضمان معاملة الضمان أو التنفيذ المناسب.
              </p>
              <p className="bottom">
                <b>السيولة</b> – المعاملات الشفافة خدمة ممتعة. تعزز الشفافية
                الثقة وحسن النية. العمليات التي تجري في الضمان لكل معاملة شفافة
                حيث أنها متاحة لجميع مستخدمي بلوكشين.
              </p>
              <p className="bottom">
                <b>فعاله من حيث التكلفه </b>– بلوكشين يلغي الحاجة إلى أطراف
                ثالثة ، مما يساعد بدوره في تقليل تكلفة المعاملة وبناء كفاءة
                الخدمة.
              </p>
              <p className="bottom">
                <b>حماية استعادة الأموال</b> – يتلقى المشتري أكبر قدر من الحماية
                في عملية الضمان. في حالة عدم الوفاء بعملية الشراء بناءً على
                الاتفاقية ، يطمئن المشتري إلى أنه سيتم إرجاع الأموال.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <h1 className="bottom">كيف يعمل الضمان؟</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-9">
              <p>
                <b>1. إنشاء حساب الضمان.</b>
              </p>
              <p>
                يستفسر المشتري عن المنتج أو العرض ويبدأ في إنشاء حساب في HVAM.
                يمكن للمشتري أو البائع بدء المعاملة. بعد إنشاء الحساب ، يجب الآن
                شرح شروط وأحكام العقد والاتفاق عليها من قبل الطرفين لبدء
                المعاملة.
              </p>
            </div>
            <div className="col-12 col-md-3">
              <img src={secone} className="img-responsive" alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-9">
              <p>
                <b>2. تمويل حساب HVAM.</b>
              </p>
              <p>
                يمكن للمشتري الآن تحويل الأموال (العملة المشفرة) إلى حساب الضمان
                HVAM لحفظها. سوف تتحول تلقائيًا إلى عملة ثابتة وسيتم تخزينها في
                حساب ضمان. تتم حماية تقلبات العملة المشفرة حتى اكتمال المعاملة.
              </p>
            </div>
            <div className="col-12 col-md-3">
              <img src={sectwo} className="img-responsive" alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-9">
              <p>
                <b>3. تأكيد التسليم.</b>
              </p>
              <p>
                يتلقى البائع الإخطار ، ويمكنه الآن تسليم المنتج وإرسال معلومات
                التسليم الضرورية إلى خدمة الضمان HVAM. بمجرد أن يؤكد المشتري ما
                إذا كان المنتج قد تم تسليمه ، يكون لدى المشتري الآن فترة فحص
                محددة للتحقق من المنتج قبل القبول أو الرفض. سيتم إجراء عملية
                تسوية المنازعات على المنصة إذا صادف المشتري رفض المنتج ،
              </p>
            </div>
            <div className="col-12 col-md-3">
              <img src={secthree} className="img-responsive" alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-9">
              <p>
                <b>4. يطلق HVAM الدفعة للبائع.</b>
              </p>
              <p>
                إذا كان المنتج يفي بالمواصفات والشروط المتفق عليها ، فقد تقوم
                HVAM الآن بتحرير الأموال إلى البائع وفقًا لذلك
              </p>
            </div>
            <div className="col-12 col-md-3">
              <img src={secfour} className="img-responsive" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <p className="center-text">
                يوضح الرسم البياني أن العقد الذكي يمكن أن يلعب دور الضمان الذي
                يحتفظ بالأموال حتى يتم استيفاء شروط معينة. (1) تحديد شروط
                وإجراءات التسوية. (2) يقوم الطرف الثالث أو المشتري بنقل الرمز
                المميز إلى عقد الضمان الذكي. (3) التحقق من الشروط الصادرة إذا تم
                استيفاء المنتجات / الخدمات المرغوبة. (4) يطلق الرمز (الرموز)
                للطرف الأول أو البائع.
              </p>
              <br />
              <p className="center-text">حساب الضمان بلوكتشين الانسيابي</p>
              <img src={secfive} className="img-responsive" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <h1 className="bottom color-org">محفظة HVAM</h1>
              <p>
                <b>قم بتخزين جميع أصولك في مكان واحد.</b>
              </p>

              <p>
                لقد طورنا محفظتنا الخاصة من أجلك لتخزين العملات المشفرة و NFTs
                الخاصة بك بشكل آمن ، وبدء إرسال الرموز واستلامها وتبادلها
                والوصول إلى سوق NFT والمدفوعات. محفظة HVAM هي محفظة ذاتية العهدة
                ، والتي تمتلكها بالكامل لأصولك ومفاتيحك الخاصة وبياناتك. تأمين
                أصولك باستخدام تشفير مفتاح متعدد التوقيع واختياري ويستخدم العديد
                من آليات الأمان (عبارة سرية) لحماية أصول العميل من القرصنة أو
                الاحتيال ويزودك بتسجيل دخول المستخدم وكل ما تحتاجه لإدارة أصولك
                الرقمية. سيكون متاحًا لنظامي IOS و Android.
              </p>
              <ul className="wallet-ul">
                <li>تتبع الرسوم البيانية والمعاملات والأسعار داخل التطبيق.</li>
                <li>بطاقة تعبئة الرصيد.</li>
                <li>
                  شراء وبيع وتخزين ومشاركة العملات المشفرة ومجموعات NFT الخاصة
                  بك.
                </li>
                <li>استكشف التطبيقات اللامركزية داخل المحفظة.</li>
                <li>قم بتبديل الرموز المتعددة بسهولة.</li>
              </ul>
            </div>
            <div className="col-12 col-md-4">
              <img src={walletim} className="img-responsive" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <h1 className="bottom color-org">مبادلة HVAM</h1>
              <h3 className="bottom">صرف العملات الرقمية إلى HVAM</h3>

              <p>
                التبادل الرقمي كورين مبادلة يشير إلى تبادل رمز مميز بآخر. أصبح
                تبادل العملات المشفرة معروفًا في المنصات الرئيسية ويتم الآن
                تعميمه كنقطة بيع في البورصات. العملية سريعة بالفعل من خلال
                التصميم ، لذا فإن العوامل التنافسية تنحصر في التنوع ورسوم
                المعاملات. لقد صممنا مبادلة الخاصة بنا ، وهي تكامل في محفظتنا
                حيث يمكن للمشاركين تبادل العملات الرقمية في HVAM ؛ أكثر أمانًا
                وموثوقية
              </p>
            </div>
            <div className="col-12 col-md-4">
              <img src={swapim} className="img-responsive" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <h1>منصة NFT</h1>
              <p className="bottom">
                <b>
                  منصة NFT إنشاء وبيع وشراء والإعلان عن مجموعات NFT الفريدة.
                </b>
              </p>
              <p>
                منصة NFT قم بإنشاء وبيع وشراء والإعلان عن مجموعات NFTs الفريدة
                من نوعها.الرمز غير القابل للاستبدال هو ضمان مالي يتكون من بيانات
                رقمية مخزنة في بلوكشين. يتم تسجيل ملكية NFT في بلوكشين ، ويمكن
                نقلها من قبل المالك ، مما يسمح ببيع وتداول NFT. تقدم HVAM سوقًا
                رمزية غير قابلة للاستبدال للمستخدمين والمستثمرين والمالكين
                لإنشاء وشراء والإعلان عن مجموعات NFT الفريدة الخاصة بهم.
              </p>
            </div>
            <div className="col-12 col-md-4">
              <img src={plateformim} className="img-responsive" alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8">
              <h1>مشروع NFT</h1>
              <p>وصول حصري إلى مجموعات NFT الفريدة الخاصة بنا.</p>
            </div>
            <div className="col-12 col-md-4">
              <img src={projectim} className="img-responsive" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9">
              <h3 className="bottom color-org">الغرض من دفع الضمان HVAMs</h3>
              <p>
                الغرض الرئيسي من دفع الضمان HVAM هو الهدف الرئيسي من دفع الضمان
                HVAM إذا كان لضمان أعلى مستوى من الأمان من المعلومات الشخصية إلى
                التفاصيل المالية في منتصف التبادل / المعاملات. نعلم جميعًا أن
                الثقة هي أحد العوائق التي تجعل الأفراد الآخرين يرفضون الاستثمار:
                خائفون من تعرضهم للخداع. خائف من الدفع أولاً قبل استلام المنتج.
                خائفًا من أن المنتج الذي قد يتلقونه ليس في حالة جيدة وليس كما
                توقعوه. خدمة الضمان HVAM هي وسيط يحتفظ بالعملات المشفرة للمعاملة
                في مكان آمن حتى يتم فحص المنتج والأسهم والأصول وما إلى ذلك من
                قبل المشتري وتأكيدها بناءً على الشروط المتفق عليها.
              </p>
            </div>
            <div className="sec15-right1"></div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3"></div>
            <div className="col-12 col-md-9 text-right">
              <h3 className="bottom align-rigt color-org">
                لماذا تختار HVAM الضمان اللامركزي؟
              </h3>
              <p className="align-rigt">
                تهدف منصة HVAM إلى توفير بيئة تداول أكثر أمانًا. ضمان التشفير
                مؤمن لأنه يحتفظ بأموالك في التخزين البارد - في عزلة مادية عن
                أنظمة تكنولوجيا المعلومات - مما يعني أنه لا يمكن اختراقها. تعمل
                خدمات ضمان التشفير هذه على بلوكشين ، لذا يمكنك أن تطمئن إلى أنه
                سيتم تأمين معلوماتك وأموالك. على عكس خدمة الضمان التقليدية ،
                تتمتع مدفوعات الضمان التي تتم على بلوكشين بميزة كبيرة: لن يكون
                الصندوق تحت السيطرة الكاملة لممثل واحد (مركزي). كل هذا ممكن بسبب
                العقود الذكية - القدرة على جدولة المعاملات لتنفيذ إجراءات معينة
                بناءً على سلسلة من الشروط الشفافة والمحددة مسبقًا وغير القابلة
                للتغيير
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <img src={teamim} alt="" className="img-responsive" />
            </div>
            <div className="col-12 col-md-8">
              <h1 className="bottom color-org">الفريق</h1>
              <p>
                فريق تقنيون ذوو مهارات عالية ومتحمسون وقادة فريق يتمتعون بخبرة
                كبيرة في صناعة التجارة والأعمال ، مع مستشارين اقتصاديين يهتمون
                بأدق التفاصيل.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 dnone">
              <img src={customerim} alt="" className="img-responsive" />
            </div>
            <div className="col-12 col-md-8">
              <h1 className="bottom sec4-right2-text color-org">
                إحصاءات العملاء
              </h1>
              <p className="sec4-right2-text">
                جيل زد ، جيل الألفية ، الجيل العاشر ، مواليد. يتزايد الجيل Z
                والجيل X في السوق ويستمران في الصعود في الوقت المناسب. من ناحية
                أخرى ، يعتبر جيل الألفية من أكثر الأجيال الحالية ذكاءً من
                الناحية التقنية في السوق. وبالتالي ، نضمن أن منصتنا يمكنها تلبية
                احتياجات كل فرد فضولي في HVAM بغض النظر عن الجيل.
              </p>
            </div>
            <div className="col-12 col-md-4 dblock">
              <img src={customerim} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <img src={innovationim} alt="" className="img-responsive" />
            </div>
            <div className="col-12 col-md-8">
              <h1 className="bottom color-org">التعاون</h1>
              <p>
                طورت HVAM موقعًا إلكترونيًا جديدًا للتداول يكون أكثر إبداعًا
                ويسهل الوصول إليه لراحة المستخدمين. في السنوات الثلاث المقبلة ،
                تتجه HVAM لدخول مجموعة متنوعة من المنصات الناجحة في صناعة
                التجارة ، واتخاذ قرار للشركة في الجزء الجنوبي من القارة
                الأوروبية وإنشاء المزيد والمزيد من المشاريع التي يمكن أن تقدم
                فوائد كبيرة لعملائنا ومستثمرينا . نهدف إلى دمج المزيد من التطوير
                مع عمليات الدمج المستقبلية لمنصتنا ؛ الخدمات المرتجلة وتقدير
                الأسعار الدائم
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 dnone">
              <img src={opportunityim} alt="" className="img-responsive" />
            </div>
            <div className="col-12 col-md-8">
              <h1 className="bottom sec4-right2-text color-org">فرصة</h1>
              <p className="sec4-right2-text">
                الشركة الأكثر نفوذاً ومقرها بلوكشين. يمكننا نقل أهدافنا من خلال
                منصتنا ، وهي تحديث الوظائف التقليدية للخدمات المالية من خلال
                تمكين العملة المشفرة لاستخدامها بأي شكل من الأشكال. تقدم HVAM
                مشاهد لتطوير نظام مفتوح المصدر ، وقيود ، وشفافية للخدمات
                المالية. يعد التحكم الكامل في أصولك ومفتاح محفظتك أحد الفوائد
                التي يمكنك الحصول عليها في HVAM
              </p>
            </div>
            <div className="col-12 col-md-4 dblock">
              <img src={opportunityim} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9">
              <h1 className="bottom color-org">تطوير موقع ويب HVAM</h1>
              <p>
                تطوير موقع ويب HVAM لنواجه الأمر! لقد غيرت خدمات تطوير مواقع
                الويب بشكل كبير كيفية عمل صناعة الأعمال. من ناحية أخرى ، يمكن أن
                يؤدي تطوير مواقع الويب إلى زيادة المبيعات والوصول إلى المزيد من
                العملاء والوعي بالعلامة التجارية ولكي يستمتعوا بتصفح الموقع.
                سنستمر في تطوير المنصة حتى نصل إلى الهدف المتمثل في منح
                المستخدمين أفضل جودة وأكثرها أمانًا وأسهل خدمة
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-9">
              <h1 className="bottom sec4-right2-text color-org">معلما</h1>
              <p className="sec4-right2-text">
                إنشاء موقع إلكتروني مبتكر للتواصل مع الجمهور. نجحت في دعوة
                المستثمرين المقربين لدعم المشروع ، وتصميم تقدم تكنولوجي قوي ،
                وتقديم خدمة ممتازة للمستخدمين ، وتوسيع أماكن العمل. في السنوات
                الثلاث المقبلة ، تهدف HVAM إلى التنويع.
              </p>
            </div>
            <div className="col-12 col-md-3">
              <img src={milestoneim} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 text-center">
              <h1 className="bottom color-org">شرعية</h1>
              <p>
                شرعية معتمدة كشركة خاصة مسجلة ومطورة في إنجلترا وويلز. تحصل HVAM
                على شهادات تثبت شرعية الشركة. .
              </p>
              <br />
              <img
                src={legitimacyim}
                className="img-responsive m-auto"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <h1 className="bottom color-org">تكييف بلوكشين: خطاب اعتماد</h1>
              <p>
                يعتبر خطاب الاعتماد الأداة الرئيسية في التجارة الدولية. إنه عقد
                مفيد بين البنك وعميل البنك والمستفيد. بشكل أساسي ، فإن بنك
                المستورد هو الذي سيصدر خطاب الاعتماد لضمان دفع المستفيد بمجرد
                استيفاء شروط خطاب الاعتماد ، وبالتالي فإن النسخة الكلاسيكية منه
                معقدة لأن عمليتها طويلة وتستغرق وقتًا طويلاً وتكلفة عالية. بصرف
                النظر عن ذلك وفقًا للمسح (B. Consulting Group ، 2017) فمن النادر
                جدًا أن تصل السلعة / التسليم إلى الوجهة المقصودة ، وستستغرق
                المستندات اللازمة وقتًا طويلاً قبل أن يتم التحقق منها من قبل
                البنك و 1٪ فقط من المعاملات بين كل مشارك من بين الآلاف يخلق
                قيمة.
              </p>
              <br />
              <p>
                يعد الحصول على تقنية بلوكشين من حيث LC ميزة كبيرة لكل متداول.
                يتم إصدار خطاب الاعتماد على شبكة تقنية لدفتر الأستاذ الموزع تشمل
                المصدر والبنوك والمستورد والمكونات الأخرى التي تعمل كعقد مشاركة.
                ستعمل تقنية بلوكشين في L / C على تبسيط عملية كل معاملة ، وستساعد
                بلوكشين على التخلص من مخاطر المستندات المزيفة وستقلل من رسوم كل
                معاملة. يمكن أن تتم إجراءات التجارة تلقائيًا من خلال تسلسل
                العقود الذكية الرقمية التي يتم تنفيذها ذاتيًا ، ويتم استيفاء شرط
                معين واحد. باختصار ، يعد تكييف بلوكشين في خطاب الاعتماد مناسبًا
                من إنشاء ائتمان في البنك وإرساله إلى المستفيد حتى السداد ويمكن
                أن يجعل عملية المعاملة بأكملها آمنة وسريعة وفعالة من حيث
                التكلفة.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <h1 className="bottom color-org">
                مزايا تقنية بلوكشين في خطاب الاعتماد:
              </h1>
              <ul>
                <li>
                  مزايا تقنية تكنولو بلوكشين في خطاب الاعتماد تلغي الحاجة إلى
                  "العرض المادي" للوثائق وما إلى ذلك مما يقلل من الوقت وجهد
                  العمل لخطابات الاعتماد.
                </li>
                <li>
                  إمكانية عالية لجعل كل معاملة دفع في التجارة العالمية أكثر
                  موثوقية وفعالية وأسهل لجميع الأطراف في خطاب الاعتماد.
                </li>
                <li>يزيل ميول المستندات المزورة ويقلل من رسوم المعاملاتs.</li>
                <li>
                  عملية الدفع المؤتمتة على بلوكشين تضمن سرعة وأمان. سيقلل من أي
                  نزاعات وتأخيرات في الدفع بسبب أي اختلافات من جميع الأطراف.
                </li>
                <li>
                  بلوكشين يلغي ضرورة البنوك المراسلة في معاملات الاعتماد
                  المستندي
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 text-center">
              <h1 className="bottom color-org">
                مزايا تقنية بلوكشين في خطاب الاعتماد:
              </h1>
              <img src={creditim} className="img-responsive" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <h1 className="bottom color-org">فهم KYC و AML</h1>
            </div>
            <div className="col-12 col-md-6">
              <p>
                تتوسع إجراءات "اعرف عميلك" و "مكافحة غسل الأموال" للاستمرار مع
                الطلب المتزايد على الخدمات المالية والحاجة الماسة إلى زيادة
                الأمن والحماية من العناصر الإجرامية مثل غسيل الأموال وتمويل
                الإرهاب.
              </p>
              <br />
              <p>
                يتطلب القانون تقديم "اعرف عميلك" و "مكافحة غسيل الأموال" و KYC و
                AML للتخفيف من مخاطر الاحتيال المالي على الشركات. يحتوي على
                إجراءات مختلفة للتحقق والمراقبة والتأييد ، يجب أن تكون هذه
                السياسات غير متاحة لمنع الإجراءات الضارة. إذا لم يكن الأمر كذلك
                ، فستواجه هذه الكيانات غرامات عدم امتثال عالية من الهيئات
                التنظيمية.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <p>
                اعتمدت HVAM إجراءات KYC بدقة لتأكيد أو التحقق من هوية كل مستخدم
                يحاول المشاركة في HVAM. يتفهم المصدر جوهر منع أي احتيال وجرائم
                مالية مثل غسيل الأموال وتمويل الإرهاب. تم وضع قانون لمكافحة غسيل
                الأموال لتجنب غسيل الأموال الذي يمول الإرهاب. وفقًا لهذا القانون
                ، يجب على المؤسسات المالية الإبلاغ عن المعاملات المشبوهة أو
                الأنشطة غير المعتادة ، ولكن لا يجب الإبلاغ عن ذلك - سواء عن قصد
                أم بغير قصد - وقد يُسمح بذلك. تلتزم وزارة الخزانة الأمريكية
                جيدًا بكبح جماح جميع جوانب الاحتيال في الداخل والخارج ، من خلال
                مهمة مكتب الإرهاب والاستخبارات المالية (TFI).
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-3">
              <h1 className="color-org">تنصل</h1>
            </div>
            <div className="col-12 col-md-9">
              <p className="sixee">
                يجب قراءة قسم إخلاء المسؤولية القانونية بعناية. نوصيك باستشارة
                مستشار (مستشارين) قانونيين قانونيين وضرائب وماليين وغيرهم للحصول
                على إرشادات قبل المشاركة في HVAM.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              <p>
                المعلومات الواردة في هذا الموقع بما في ذلك النصوص والرسومات
                والصور وما إلى ذلك هي لأغراض المعلومات ولا تشكل عرضًا لأداة
                مالية أو أوراق مالية وليست مسجلة لدى أي جهة حكومية كضمان ولا
                تعتبر كذلك. ينطبق إخلاء المسؤولية القانوني هذا على الورق الأبيض
                و / أو أي معلومات / مستندات أخرى متاحة على الموقع الإلكتروني. قد
                يتم تحديث إخلاء المسؤولية القانوني والمعلومات الأخرى المتاحة على
                موقع الويب من وقت لآخر ويتم نشرها كأحدث إصدار من المستند التقني.
                قد لا تكون المعلومات الواردة في قسم إخلاء المسؤولية القانونية
                هذا كاملة ولا تستدل على أي نوع من التحالف التعاقدي. جميع
                المعلومات دقيقة وحديثة ، وهذه المواد لا تشكل بأي حال من الأحوال
                نصيحة مهنية.
              </p>
              <br />
              <p>
                لا يُنظر إلى المستند التقني وأي معلومات أخرى بما في ذلك النصوص
                والرسوم البيانية والصور و / أو أي مستندات مُشار إليها على موقعنا
                على الإنترنت في أي نوع من التماس الاستثمار أو المشورة التجارية
                أو القانونية أو الضريبية المتعلقة بالشركة ، أو أي من الأمور التي
                كل المعلومات المتاحة أو أي جزء منها تتعلق. استشارة مستشارك
                القانوني أو المالي أو الضريبي أمر لا بد منه قبل المشاركة في
                الشركة
              </p>
              <br />
              <p>
                الأمر متروك لك تمامًا لعدم الحاجة إلى موافقة مسبقة أو تالية أو
                تسجيل أو ترخيص أو إذا كان ذلك مطلوبًا ، فالأمر متروك لك تمامًا
                للحصول على مثل هذه الموافقة المسبقة أو اللاحقة أو التسجيل أو
                الترخيص أو طلب أي نموذج في البلد الذي يوجد فيه كنت مواطنًا أو
                مواطنًا أو مقيمًا أو لديك عامل ربط مشابه أو مدمج أو مسجل أو
                مُدار بشكل فعال ، ولن تكون جهة الإصدار مسؤولة بأي حال من الأحوال
                عن أي عدم امتثال لما سبق من قبلك
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 text-center pb50">
              <img src={magerodmap} className="img-responsive" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="section for-right last-direction">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <h1 className="color-org bottom">Social Media</h1>
              <div className="color-org">
                <img
                  src={facebookim}
                  className="faceook-icon width-all"
                  alt=""
                />
                <b>Facebook: /hvambit</b>
              </div>
              <div className="color-org">
                <img src={instaim} className="faceook-icon width-all" alt="" />
                <b>Instagram: /hvambit/</b>
              </div>
              <div className="color-org">
                <img src={tiktokim} className="faceook-icon width-all" alt="" />
                <b>Tiktok: /@hvambit</b>
              </div>
              <div className="color-org">
                <img
                  src={whatsappim}
                  className="faceook-icon width-all"
                  alt=""
                />
                <b>+44 7488 8808 02</b>
              </div>
              <div className="color-org">
                <img src={emailim} className="faceook-icon width-all" alt="" />
                <b>Email: info@hvambit.com </b>
              </div>
              <div className="color-org">
                <img
                  src={websiteim}
                  className="faceook-icon width-all"
                  alt=""
                />
                <b>WWW.HVAMBIT.COM</b>
              </div>
            </div>
            <div className="col-12 col-md-6 pt30">
              <img src={lastim} className="img-responsive" alt="" />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default WhitepaperArabic;
