import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

const Privacy = () => {
  return (
    <>
      <Header />
      <section id="hero" className="pt80 bg-dark heromian"></section>
      <section id="services" className="pt100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 text-left">
              <h2 className="f-xbold">General</h2>
              <hr className="seperator disply-inline-block" />
              <p>
                This Privacy Policy governs collection, processing and use by
                HVAMBIT (hereinafter – the “Company” or “we”, “us”, “our’, etc.)
                of your Personal Information that you provide us when accessing
                or using the website www. HVAMBIT.com (hereinafter – the
                “Website”) or related services provided by the Company via the
                Website or according to our agreement with you (hereinafter –
                “Services”).{" "}
              </p>
              <p>
                “Personal Information” refers to information that identifies an
                individual, such as name, address, e-mail address, financial
                information, and banking details. “Personal Information” does
                not include anonymized and/or aggregated data that does not
                identify a specific user. Company is committed to protecting and
                respecting your privacy. The purpose of this Privacy Policy is
                to describe:
              </p>
              <p>
                The types of Personal Information we collect and how it may be
                used; How and why we may disclose your Personal Information to
                third parties; The transfer of your Personal Information; Your
                right to access, correct, update, and delete your Personal
                Information; The security measures we use to protect and prevent
                the loss, misuse, or alteration of Personal Information; and
                Company’s retention of your Personal Information;
              </p>
              <p>
                This Privacy Policy also covers some basics of our use of
                cookies, however, for more details please see also our Cookie
                Policy;
              </p>
              <p>
                We control the ways of collecting your Personal Information, and
                determine goals for which We use Personal Information. We are a
                “data controller” within the meaning of the General Data
                Protection Regulation (EU) 2016/679 (hereinafter referred to as
                “GDPR”) and other applicable European laws pertaining to data
                protection.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="pt100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 text-left">
              <h2 className="f-xbold">
                Collection and use of personal information
              </h2>
              <hr className="seperator disply-inline-block" />
              <h4 className="pt20 pb20">A. Personal information we collect</h4>
              <p>
                We collect the next Personal Information: Contact information,
                such as name, home address, and E-mail address. Account
                information, such as username and password.
              </p>
              <p>
                Financial information, such as bank account numbers, bank
                statement, trading activity and history, commissions charged,
                etc.
              </p>
              <p>
                Identity verification information, such as images of your
                government issued ID, passport, national ID card, driving
                license or other documents requested by our compliance
                department. Residence verification information, such as Utility
                bill details or similar information. Other information, obtained
                during our KYC procedures, such as information about your
                financial state and source of funds.
              </p>
              <p>
                We also automatically collect certain computer, device, and
                browsing information when you access the Website or use of
                Services. This information is aggregated to provide statistical
                data about our users’ browsing actions and patterns, and does
                not personally identify individuals. This information may
                include:
              </p>
              <p>
                The information about the computer or mobile device you use to
                access our Website, including the hardware model, operating
                system and version, the web browser you use, IP addresses and
                other device identifiers.
              </p>
              <p>
                Website usage information, the server log information, which may
                include (but is not limited to) your login details, the date and
                time of visits, the pages viewed, your IP address, time spent at
                our Website and the websites you visit just before and just
                after our Website. The bandwidth upload and download speeds, the
                amount of free and used storage space on your device and other
                statistics about your device.
              </p>
              <p>
                We may automatically capture, store and otherwise process
                information about you even if you abandon the completion of an
                online application or registration form.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12 text-left">
              <h4 className="pt20 pb20">
                B. Use of cookies and similar technology
              </h4>
              <p>
                The Website is using cookies. Cookies are small text files that
                are placed on your computer by websites that you visit. They are
                widely used in order to make websites work, or work more
                efficiently, as well as to provide information to the owners of
                the site. Cookies are typically stored on your computer’s hard
                drive.{" "}
              </p>
              <p>
                Our Website uses cookies to enable you to use the Website, the
                Services we offer and the materials on the Website. Cookies are
                also used to distinguish you from other users of our Website.
                This helps us to provide you with a good experience when you
                browse our Website and also allows us to improve our Website.{" "}
              </p>
              <p>
                For detailed information on the cookies we use and the purposes
                for which we use them see our Cookie Policy.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12 text-left">
              <h4 className="pt20 pb20">
                C. How we use your personal information
              </h4>
              <p>
                We may use your Personal Information to: Process your
                transactions. We will process your Personal Information only for
                the purpose(s) for which it has been provided to us.
              </p>
              <p>
                <b>Fulfill our legal or regulatory requirements;</b>
              </p>
              <p>
                Verify your identity in accordance with the applicable
                legislation and the Company’s Anti Money Laundering policy, as
                well as address other law enforcement needs. We also may share
                your information with other financial institutions and with tax
                authorities if such actions are required from us due to any
                applicable legislation.
              </p>
              <p>
                Detect, investigate and prevent fraudulent transactions or
                unauthorized or illegal activities; Protect of our rights and
                property;
              </p>
              <p>
                <b>Personalize your Services experience.</b>
              </p>
              <p>
                Analyze Website usage, and improve our Website and Website
                offerings. Analyzing and tracking data to determine the
                usefulness or popularity of certain content and to better
                understand the online activity of our Website users;
              </p>
              <p>
                Help us respond to your customer service requests and support
                needs, answer your inquiry or respond to a communication from
                you;
              </p>
              <p>
                Contact you about Services. The email address you provide may be
                used to communicate information and updates related to your use
                of the Services. We may also occasionally communicate technical
                notices, support or administrative notifications, company news,
                updates, promotions, and related information relating to similar
                products and Services provided by Company.
              </p>
              <p>
                Administer a contest, promotion, survey or other features as
                will be more explained on the Website. Link, connect or combine
                Personal Information we collect from or about you with other
                Personal Information; and Carry out any other purpose or reason
                for which the Information was collected. We do not perform
                behavioral tracking of a customer’s activities on our Website or
                across different Websites, nor do we allow third-party data
                collection through our services. If you wish to stop receiving
                marketing communications from us, please contact us at
                support@hvambit.com to opt-out.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="pt100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 text-left">
              <h2 className="f-xbold">
                3. Disclosing and transferring personal information
              </h2>
              <p>
                We may disclose your Personal Information to third parties and
                legal and regulatory authorities, and transfer your Personal
                Information as described below:
              </p>
              <hr className="seperator disply-inline-block" />
              <h4 className="pt20 pb20">A. Disclosures to third parties</h4>
              <p>
                In processing your transactions, we may share some of your
                Personal Information with our third party service providers who
                help with our business operations. Your information will not be
                sold, exchanged, or shared with any third parties without your
                consent, except to provide Services or as required by law. By
                using Website or Services you consent to the disclosure of your
                Personal Information as described in this Privacy Policy.
                Non-personally identifiable visitor information may be provided
                to third parties for marketing, advertising, or other uses.
                Company’s third party service providers are contractually bound
                to protect and use such information only for the purposes for
                which it was disclosed, except as otherwise required or
                permitted by law. We ensure that such third parties will be
                bound by terms no less protective those described in this
                Privacy Policy, or those we are subject to under applicable data
                protection laws.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12 text-left">
              <h4 className="pt20 pb20">
                {" "}
                B. Disclosures to legal authorities
              </h4>
              <p>
                We may share your Personal Information with law enforcement,
                data protection authorities, government officials, and other
                authorities when: Compelled by subpoena, court order, or other
                legal procedure. We believe that the disclosure is necessary to
                prevent physical harm or financial loss. Disclosure is necessary
                to report suspected illegal activity. Disclosure is necessary to
                investigate violations of this Privacy Policy or any our
                agreement with you.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-12 text-left">
              <h4 className="pt20 pb20">
                С. International transfers of personal information
              </h4>
              <p>
                We store and process your Personal Information in data centers
                around the world, wherever Company facilities or service
                providers are located. As such, we may transfer your Personal
                Information between such data centers. Such transfers are
                undertaken in accordance with our legal and regulatory
                obligations and are performed only via protected channels.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12 text-left">
              <h4 className="pt20 pb20">
                D. Other circumstances for disclosure of personal information
              </h4>
              <p>
                We also can disclose your Personal Information in the following
                circumstances: with your consent or at your instruction. Certain
                information you may choose to share may be displayed publicly,
                such as your username and any content you post when you use
                interactive areas of our Website like our online forums;
              </p>
              <p>
                with our current or future parent companies, affiliates,
                subsidiaries and with other companies under common control or
                ownership with us or our offices internationally. We ensure that
                listed parties will be bound by terms not less protective those
                described in this Privacy Policy, or those we are subject to
                under applicable data protection laws;
              </p>
              <p>
                if the sharing of Personal Information is necessary for
                protection of our rights and property, or rights and property of
                the above listed current or future parent companies, affiliates,
                subsidiaries and with other companies under common control or
                ownership with us or our offices;
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="pt100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 text-left">
              <h2 className="f-xbold">External websites</h2>
              <hr className="seperator disply-inline-block" />
              <p>
                Occasionally, the Website may provide references or links to
                other websites (“External websites”). We do not control these
                External websites third party sites or any of the content
                contained therein. You agree that we are in no way responsible
                or liable for External websites referenced or linked from the
                Company’s Website, including, but not limited to, website
                content, policies, failures, promotions, products, services or
                actions and/or any damages, losses, failures or problems caused
                by, related to, or arising from those sites. External websites
                have separate and independent privacy policies. We encourage you
                to review the policies, rules, terms, and regulations of each
                site that you visit. We seek to protect the integrity of our
                site and welcome any feedback about External website information
                provided on the Company Website.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="pt100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 text-left">
              <h2 className="f-xbold">
                Your rights regarding your personal information
              </h2>
              <hr className="seperator disply-inline-block" />
              <p>
                You are able to exercise all the right foreseen by legislation,
                if this does not contradict the legal requirements in relation
                to the prevention of money laundering and terrorist financing
                laws, our record keeping obligations, etc.
              </p>
              <p>
                <b>You have the following rights:</b>
              </p>
              <ul>
                <li>
                  the right to be informed about the fact that we’re processing
                  your Personal Information and which data exactly we are
                  processing,
                </li>
                <li>
                  the right to data portability. In certain circumstances, you
                  have the right to obtain all your Personal Information we
                  store in a machine readable format;
                </li>
                <li>
                  the right to object the processing of your Personal
                  Information.
                </li>
                <li>
                  the right not to be subject to automated decision-making
                  including profiling if it doesn’t intervene in performing the
                  contract between you and us.
                </li>
                <li>
                  the right to access your Personal Information, to correct,
                  update, and block inaccurate and/or incorrect data;
                </li>
                <li>
                  the right to withdraw your consent of Personal Information
                  processing;
                </li>
                <li>
                  the right to erase your Personal Information from our servers
                  upon your justified request (a right to be forgotten);
                </li>
              </ul>
              <p>
                To exercise these rights, contact us at support@hvambit.com.{" "}
              </p>
              <p>
                Within 40 days of receipt of your written request we will
                provide you with your Personal Information, including the
                purposes for which it was used and to whom it was disclosed to
                in accordance with applicable legislation. We reserve the right
                to request additional information from you, that may be
                necessary in order to provide the duly respond to your request
                in accordance with applicable legislation and you agree with
                such our right. Also, if you wish to correct, update, and block
                inaccurate and/or incorrect data, we have a right to request a
                confirmation of correct data from you, for example, official
                documents containing such data.
              </p>
              <p>
                Please note that if we are unable to verify your identity by
                e-mail messages or at your application to the call center, or in
                the case of reasonable doubts concerning your identity, we may
                ask you to provide a proof of identity, including by personal
                appearance in our office. This is the only way we can avoid
                disclosing your Personal Information to a person who can
                perpetrate your identity. In some cases, we will not be able to
                change your Personal Information. In particular, such case can
                include the event when your personal information has already
                been used during the execution of any agreement or transaction,
                or is specified in any official document, etc.{" "}
              </p>
              <p>
                You have the right of withdrawal of consent to personal data
                processing. You may also exercise your right to be forgotten and
                erase your Personal information from our servers. In cases
                stipulated in Article 17 of GDPR, we will delete your Personal
                Information we process, except for those Personal Information
                which we are obliged to store in accordance with the
                requirements set forth by the applicable legislation.{" "}
              </p>
              <p>
                Please be advised, that in case of realization of your right of
                withdrawal of consent to personal data processing or right to be
                forgotten, we will may not be able to provide you our products
                or services, and we have a special right to terminate all our
                current agreements with you with the application of legal
                consequences of such termination, and you irrevocably
                acknowledge such our right.{" "}
              </p>
              <p>
                In order to withdraw the consent to personal data processing
                and/or exercise your rights to be forgotten please contact us at
                support@hvambit.com. Furthermore, in this case, for the purposes
                of safety, we may request you to present your ID document,
                including directly at our office.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="pt100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 text-left">
              <h2 className="f-xbold">Security of personal information</h2>
              <hr className="seperator disply-inline-block" />
              <p>
                We use a variety of security measures to ensure the
                confidentiality of your Personal Information, and to protect
                your Personal Information from loss, theft, unauthorized access,
                misuse, alteration or destruction. These security measures
                include, but are not limited to: Password protected directories
                and databases. Secure Sockets Layered (SSL) technology to ensure
                that your information is fully encrypted and sent across the
                Internet securely.
              </p>
              <p>
                Limited access to hosting servers using 2FA and traffic
                encryption. All financially sensitive and/or credit information
                is transmitted via SSL technology and encrypted in our database.
                Only authorized Company personnel are permitted access to your
                Personal Information, and these personnel are required to treat
                the information as highly confidential. The security measures
                will be reviewed regularly in light of new and relevant legal
                and technical developments.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="pt100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 text-left">
              <h2 className="f-xbold">Retention of personal information</h2>
              <hr className="seperator disply-inline-block" />
              <p>
                We retain Personal Information for as long as necessary to
                fulfil purposes described in this Privacy Policy, subject to our
                own legal and regulatory obligations. In accordance with our
                record keeping obligations, we will retain account and other
                Personal Information for at least five years after termination
                of respective agreement.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="pt100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 text-left">
              <h2 className="f-xbold">Updates to this privacy policy</h2>
              <hr className="seperator disply-inline-block" />
              <p>
                This Privacy Policy may be revised, modified, updated and/or
                supplemented at any time, without prior notice, at the sole
                discretion of Company. When we make changes to this Privacy
                Policy, we will notify all users on our Website, and make the
                amended Privacy Policy available on our Website.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="pt100 pb100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 text-left">
              <h2 className="f-xbold">Data protection officer</h2>
              <hr className="seperator disply-inline-block" />
              <p>
                To exercise all relevant rights, queries or complaints to any
                data protection matter between you and us, please in the first
                instance contact our Data Protection Officer at
                support@hvambit.com.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Privacy;
