import './App.css';
import Home from './Views/Home';
import { Routes, Route } from "react-router-dom";
import Roadmap from './Views/Roadmap';
import Whitepaper from './Views/Whitepaper';
import WhitepaperArabic from './Views/WhitepaperArabic';
import WalletPage from './Views/WalletPage';
import Shop from './Views/Shop';
import Privacy from './Views/Privacy';
function App() {
  return (
    <>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/whitepaper-english" element={<Whitepaper />} />
            <Route path="/whitepaper-arabic" element={<WhitepaperArabic />} />
            <Route path="/wallet-details" element={<WalletPage />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/privacy" element={<Privacy />} />
        </Routes>
    </>
  );
}

export default App;
