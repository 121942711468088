import React from "react";
import Gbtn from "../assets/img/gplay-store.png";
import whalphone from "../assets/images/wall_phone.png";
import wall_STORE from "../assets/images/wall_STORE.png";
import wall_SEND from "../assets/images/wall_SEND.png";
import wall_NFT from "../assets/images/wall_NFT.png";
import wall_1 from "../assets/images/wall_1.png";
import wall_swap from "../assets/images/wall_swap.png";
import { BsFillCheckCircleFill } from "react-icons/bs";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const WalletPage = () => {
  return (
    <>
    <Header/>
    <section id="hero" className="pt80 bg-dark heromian"></section>
      <section className="pt40 pb40 bg-yelowes">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-6 pt100">
              <h1 className="mb20 text-uppercase white">smart. secure. user-friendly</h1>
              <p className="mb20 black fs-18 f-smbold">A self-custody wallet which you're in full ownershipof your crypto, NFTs, and keys.</p>
              <p className="mb30 black fs-18 f-smbold">Download the app today and Get started!</p>
              <a href="https://play.google.com/store/apps/details?id=com.collaborics.wallet" target="blank">
                <img src={Gbtn} alt="" className="img-responsive" />
              </a>
            </div>
            <div className="col-12 col-md-6">
                <img src={whalphone} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>
      <section className="pt40 pb40 ">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-6 pt100">
              <h2 className="mb20 text-uppercase yelowes fs-55">
                store
              </h2>
              <p className="mb30 black fs-22 f-smbold">Safely and easily store all  your <br /> crypto and NFTs in one place.</p>
            </div>
            <div className="col-12 col-md-6">
                <img src={wall_STORE} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>
      <section className="pt40 pb40 ">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-6 pt100">
              <h2 className="mb20 text-uppercase yelowes fs-55">
                send
              </h2>
              <p className="mb30 black fs-22 f-smbold">Send and receive tokens, view <br /> balances and transaction records.</p>
            </div>
            <div className="col-12 col-md-6">
                <img src={wall_SEND} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>
      <section className="pt40 pb40 ">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-6 pt100">
              <h2 className="mb20 text-uppercase yelowes fs-55">
                swap
              </h2>
              <p className="mb30 black fs-22 f-smbold">Easily swap token across various <br /> chains on your HVAMBIT wallet.</p>
            </div>
            <div className="col-12 col-md-6">
                <img src={wall_swap} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>
      <section className="pt40 pb40 ">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-6 pt100">
              <h2 className="mb20 text-uppercase yelowes fs-55">
                NFT
              </h2>
              <p className="mb30 black fs-22 f-smbold">Simply view your NFTs and its rarities.</p>
            </div>
            <div className="col-12 col-md-6">
                <img src={wall_NFT} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>
      <section className="pt40 pb40 bg-yelowes">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-6 pt100">
              <h2 className="mb20 white f-normal f-norma-italic">
              "Use it with ease. HVAMBIT wallet."
              </h2>
              <ul>
                <li className="mb10">
                  <p className="mb0 black f-mbold"><BsFillCheckCircleFill className="green mr10"/> leading security</p>
                  <span className="ml30 black">Private keys are encrypted on your device.</span>
                </li>
                <li className="mb10">
                  <p className="mb0 black f-mbold"><BsFillCheckCircleFill className="green mr10"/> User-Friendly</p>
                  <span className="ml30 black">Simple but secured.</span>
                </li>
                <li className="mb10">
                  <p className="mb0 black f-mbold"><BsFillCheckCircleFill className="green mr10"/> Ful control your assets and keys.</p>
                </li>
                <li className="mb10">
                  <p className="mb0 black f-mbold"><BsFillCheckCircleFill className="green mr10"/> View your collectibles -NFTs.</p>
                </li>
                <li className="mb10">
                  <p className="mb0 black f-mbold"><BsFillCheckCircleFill className="green mr10"/> Buy, send and swap in minutes.</p>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6">
                <img src={wall_1} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
};

export default WalletPage;
