import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
const Roadmap = () => {
  return (
    <>
      <Header/>
      <section id="hero" className="pt80 bg-dark heromian"></section>
        <section className="road-mapsec bg-dark pt100 pb150">
          <div className="container text-center">
          <div className="row">
              <div className="col-12">
                <h1 className="maintitleu">HVAMBIT ROADMAP</h1>
              </div>
            </div>
            <div className="row main-box pt80">
              <div className="col-md-3 col-sm-12 customcol">
                <div className="card leftouter">
                  <div className="card-body">
                    <div>
                      <h2>Website Creation </h2>
                      <p>Idea & Concept. Began marketing research.</p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">
                      <h3>100%</h3>
                      <span className="mcornerone"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 customcol">
                <div className="card mrightborder">
                  <div className="card-body">
                    <div>
                      <h2>Token Creation </h2>
                      <p>
                        2 billion tokens were created to ensure the highest
                        benefit for investors from the exchange rates.
                      </p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">
                      <h3>100%</h3>
                      <span className="mcornerthree"></span>
                      <span className="mcornertwo"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 customcol">
                <div className="card mleftborder">
                  <div className="card-body">
                    <div>
                      <h2> Private Sale </h2>
                      <p>Successfully invited close investors</p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">
                      <h3>100%</h3>
                      <span className="mcornerone"></span>
                      <span className="mcornerfour"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 customcol">
                <div className="card mrightborder">
                  <div className="card-body">
                    <div>
                      <h2> Selling Platform creation</h2>
                      <p></p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">{/* <h3>100%</h3> */}
                    <span className="cornerone"></span>
                    <span className="mcornerthree"></span>
                      <span className="mcornertwo"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row main-box">
              <div className="col-md-3 col-sm-12 customcol"></div>
              <div className="col-md-1 col-sm-12 customcol customcol-empty">
                <div className="card mleftborder">
                  <div className="card-body">
                    <div></div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">
                      <span class="cornertwo"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 customcol">
                <div className="card mleftborder">
                  <div className="card-body">
                    <div>
                      <h2>HVAMBIT Wallet </h2>
                      <p>
                        Allows merchants, shareholders, and investors to manage
                        their assets and process transactions directly.
                      </p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">
                      <h3>100%</h3>
                      <span className="mcornerone"></span>
                      <span className="mcornerfour"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 customcol">
                <div className="card rightborder mrightborder">
                  <div className="card-body">
                    <div>
                      <h2> Trading Development Platform </h2>
                      <p>
                        A trading platform that you can use to set up postpaid
                        trades (LC - DLC - SBLC).
                      </p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">
                      <h3>100%</h3>
                      <span className="cornerthree"></span>
                      <span className="mcornerthree"></span>
                      <span className="mcornertwo"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row main-box">
              <div className="col-md-3 col-sm-12"></div>
              <div className="col-md-3 col-sm-12 customcol">
                <div className="card leftborder mleftborder">
                  <div className="card-body">
                    <div>
                      <h2>Expansion of Workplaces </h2>
                      <p>Middle East </p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">
                      <h3>100%</h3>
                      <span className="cornerfour"></span>
                      <span className="mcornerone"></span>
                      <span className="mcornerfour"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 customcol">
                <div className="card mrightborder">
                  <div className="card-body">
                    <div>
                      <h2>Africa </h2>
                      <p>
                        Establish the first course in the Aftican continent from
                        the southern side.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">
                      <h3>100%</h3>
                      <span className="mcornerthree"></span>
                      <span className="mcornertwo"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 customcol">
                <div className="card mleftborder">
                  <div className="card-body">
                    <div>
                      <h2>North Africa</h2>
                      <p>
                        Establish a dedicated course in the north of the African
                        continent for countries bordering the Mediterranean Sea.
                      </p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">
                      <h3>100%</h3>
                      <span class="cornerone"></span>
                      <span className="mcornerone"></span>
                      <span className="mcornerfour"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row main-box">
              <div className="col-md-4 col-sm-12 customcol">
                <div className="card mrightborder">
                  <div className="card-body">
                    <div>
                      <h2>Entering the Arab Rain Platform</h2>
                      <p> In progress</p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">
                      <h3 className="gradient50">50%</h3>
                      <span className="cornertwo"></span>
                      <span className="mcornerthree"></span>
                      <span className="mcornertwo"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 customcol">
                <div className="card mleftborder">
                  <div className="card-body">
                    <div>
                      <h2>Entering the Binance Platform</h2>
                      <p> In progress </p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">
                      <h3 className="gradient50">50%</h3>
                      <span className="mcornerone"></span>
                      <span className="mcornerfour"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 customcol">
                <div className="card rightborder mrightborder">
                  <div className="card-body">
                    <div>
                      <h2>In Europe</h2>
                      <p>
                        Establishing a decision for the company in the southern
                        part of the European continent
                      </p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">
                      <h3>100%</h3>
                      <span className="cornerthree"></span>
                      <span className="mcornerthree"></span>
                      <span className="mcornertwo"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row main-box">
              <div className="col-md-3 col-sm-12 customcol">
                <div className="card leftborder mleftborder">
                  <div className="card-body">
                    <div>
                      <button class="btn btn-hero-road">Future projects</button>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">
                    <span className="cornerfour"></span>
                    <span className="mcornerone"></span>
                      <span className="mcornerfour"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 customcol">
                <div className="card mrightborder">
                  <div className="card-body">
                    <div>
                      <h2>HVAM POS </h2>
                      <p>Terminal</p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">
                      <h3 className="gradient25">25%</h3>
                      <span className="mcornerthree"></span>
                      <span className="mcornertwo"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 customcol">
                <div className="card mleftborder">
                  <div className="card-body">
                    <div>
                      <h2>HVAM Prepaid Card </h2>
                      <p>(Physical & Virtual)</p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">
                      <h3 className="gradient25">25%</h3>
                      <span className="mcornerone"></span>
                      <span className="mcornerfour"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 customcol">
                <div className="card rightouter mrightborder">
                  <div className="card-body">
                    <div>
                      <h2>HVAM Exchange</h2>
                      <p></p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="perc-b">
                      <h3 className="gradient25">25%</h3>
                      <span className="mcornerthree"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      <Footer/>
    </>
  );
};

export default Roadmap;
