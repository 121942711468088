import React from "react";
import Header from "../Components/Header";
import logo from "../assets/images/logo.png";
import aboutus from "../assets/images/about-us-image.png";
import goalone from "../assets/images/goal-image-one.png";
import edges from "../assets/images/edges-image.png";
import stepone from "../assets/images/stepone.png";
import steptwo from "../assets/images/steptwo.png";
import stepthree from "../assets/images/stepthree.png";
import stepfour from "../assets/images/stepfour.png";
import stepfive from "../assets/images/stepfive.png";
import beyound from "../assets/images/lets-go-beyound-image.png";
import blockc from "../assets/images/blockchain-image.png";
import ecoservices from "../assets/images/eco-services.png";
import secone from "../assets/images/section10-image1.png";
import sectwo from "../assets/images/section10-image2.png";
import secthree from "../assets/images/section10-image3.png";
import secfour from "../assets/images/section10-image4.png";
import secfive from "../assets/images/flowchart-image.png";
import walletim from "../assets/images/wallet-image.png";
import swapim from "../assets/images/swap-image.png";
import plateformim from "../assets/images/plateform-image.png";
import projectim from "../assets/images/project-image.png";
import teamim from "../assets/images/team-image.png";
import customerim from "../assets/images/customer-image.png";
import innovationim from "../assets/images/innovation-image.png";
import opportunityim from "../assets/images/opportunity-image.png";
import milestoneim from "../assets/images/milestone-imge.png";
import legitimacyim from "../assets/images/legitimacy-image.png";
import creditim from "../assets/images/credit-image.png";
import facebookim from "../assets/images/facebook-icon.png";
import instaim from "../assets/images/insta-icon.png";
import tiktokim from "../assets/images/tiktok-icon.png";
import whatsappim from "../assets/images/whatsapp-icon.png";
import emailim from "../assets/images/email-icon.png";
import websiteim from "../assets/images/website-icon.png";
import lastim from "../assets/images/last-sec-right-img.png";
import Footer from "../Components/Footer";

const Whitepaper = () => {
  return (
    <>
      <Header />
      <section id="hero" className="pt80 bg-dark heromian"></section>
      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 text-center">
              <img src={logo} alt="" className="img-responsive m-auto" />
              <h1 className="logo-font">HVAMBIT</h1>
              <h2 className="sec1-second-text">A New Vision Of Banking</h2>
              <h2 className="sec1-second-text">Services Powered By</h2>
              <h2 className="sec1-second-text">Blockchain</h2>
              <br />
              <p>Make your trade easier and faster.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <h1 className="color-org">ABSTRACT</h1>
            </div>
            <div className="col-12 col-md-6">
              <p>
                Currently cryptocurrencies play a major role. Digital assets do
                not need traditional centralized finance algorithms and
                processes. Decentralized finance is encrypted. It uses
                Cryptography for the security of transactions. Each
                cryptocurrency transaction is recorded in a public ledger called
                the blockchain and is generally a quick and straightforward
                process, meaning, it can be transferred from one digital wallet
                to another, using only a smartphone or computer.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <p>
                HVAM BIT is a new vision of Banking Services powered by
                Blockchain. Our platform offers various benefits to users. We
                are supporting commercial transactions with our blockchain
                solutions, such as: implementing insurance operations & sending
                remittances between parties. Our technology will keep the
                customer data: safe, secured, and confidential, ensuring the
                receipt and delivery. A high-quality applications are created
                with smart contracts to support sales operations and smooth
                transaction between parties.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <p>
                Blockchain is a digital database and a system of recording
                information that is designed to avoid hacks and record
                exploitations (such as financial transactions and personal
                information). It allows digital necessary information to be
                recorded and distributed. Blockchain is the base of fixed
                ledger, meaning it cannot be altered, destroyed, or modified.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 text-center">
              <h1 className="sect-heading bottom color-org">ABOUT US</h1>
              <p>
                Founded in 2021. HVAM BIT is an All-in-one platform backed by
                Blockchain. Our platform imparts its participants with exclusive
                benefits and advantages such as its own crypto wallet, trading,
                swapping, NFT platform, and crypto-based payment escrow
                services. HVAM developers and the team were keen to assess and
                observe every detail and feature of this project to achieve the
                highest security. Our project addresses one of the problems in
                the overall marketplace; “trust” by developing decentralized
                applications and escrow services. Our topmost scheme is to
                ensure every transaction and operation is safe, high-speed, and
                cost-effective. Our project is for the safe trading industry
                between businesses and individuals.
              </p>
              <img src={aboutus} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-3 dnone">
              <img src={goalone} alt="" className="img-responsive" />
            </div>
            <div className="col-12 col-md-9">
              <h1 className="bottom color-org">GOAL</h1>
              <p>
                HVAM BIT trading platform was created and developed to provide
                the safest trading environment to exchange valuable crypto and
                assets while continuing to be decentralized. One of our purposes
                is to replace the classic financial instruments (LC.DLC.SBLC),
                to blockchain financial instruments, in order to make trading
                easier and safer for businesses and individuals. We provide a
                high-quality service and extend unique opportunities to every
                participant.
              </p>
            </div>
            <div className="col-12 col-md-3 dblock">
              <img src={goalone} alt="" className="img-responsive" />
            </div>
          </div>
          <div className="row pt50">
            <div className="col-12 col-md-3">
              <img src={edges} alt="" className="img-responsive" />
            </div>
            <div className="col-12 col-md-9">
              <h1 className="bottom sec4-right2-text color-org">EDGES</h1>
              <p className="sec4-right2-text">
                All steps & processes are carefully reviewed by our team. Our
                standards make us retain & gain the trust of our new clients
                everyday. User’s information, financial security,
                confidentiality and trouble-free transactions are the topmost
                priority of our platform. The company which uses our solutions
                will be supported by 250 million dollars as risk insurance.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div class="row">
            <div className="col-12 col-md-12 text-center">
              <h1 className="sec5-top-head color-org bottom">BEYOND HVAMBIT</h1>
              <p>
                HVAM platform isn't just focusing on one product, we offer a
                large panel of solutions with high security for each
                transaction.
              </p>
            </div>
          </div>
          <div class="row pt50">
            <div className="col-12 col-md-1"></div>
            <div className="col-12 col-md-2">
              <div className="text-center">
                <img src={stepone} alt="" className="img-responsive m-auto" />
                <p className="sec5-step1-1 color-org">HVAM ESCROW SERVICES</p>
                <p>
                  Use your crypto as payment & protect your global payments
                  using HVAM escrow.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="text-center">
                <img src={steptwo} alt="" className="img-responsive m-auto" />
                <p className="sec5-step1-1 color-org">HVAM WALLET</p>
                <p>Store all your assets in one place</p>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="text-center">
                <img src={stepthree} alt="" className="img-responsive m-auto" />
                <p className="sec5-step1-1 color-org">HVAM SWAP</p>
                <p>Exchange digital currencies on HVAM</p>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="text-center">
                <img src={stepfour} alt="" className="img-responsive m-auto" />
                <p className="sec5-step1-1 color-org">NFT PLATFORM</p>
                <p>Create, sell, buy and advertise unique NFTs collections</p>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="text-center">
                <img src={stepfive} alt="" className="img-responsive m-auto" />
                <p className="sec5-step1-1 color-org">NFT PROJECT</p>
                <p>Exclusive access to our unique NFTs collections.</p>
              </div>
            </div>
            <div className="col-12 col-md-1"></div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 text-center">
              <h1 className="color-org">LET’S GO BEYOND</h1>
              <h2 className="bottom color-org">HVAM Escrow service </h2>
              <p>
                <b>
                  Use your cryptos and faster your global payments using HVAM
                  escrow.
                </b>
              </p>
              <p>
                HVAM escrow service protects both buyer and seller in a
                transaction. Keep your fund secure until goods are delivered or
                received, we provide a high-end custom solution. Get your fund
                back (transaction fees excluded) if conditions aren’t met.
              </p>
              <img src={beyound} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 text-center">
              <h2 className=" bottom">
                HVAM Escrow Service | Blockchain-Based
              </h2>
              <p>
                Decentralized escrow services enhance trust in the
                business.Traditionally, the way of storing customer’s personal
                and financial data, is using a central encoded database. But
                database can be altered or lost due to the lack of security.
                Blockchain technology and cryptocurrencies solve this problem
                combined with HVAM Escrow Solution. New service which helps you
                speed your business with serenity without the need of third
                parties Decentralized escrow is an independent mechanism that
                minimizes the need of human interactions, instead all processes
                will operate via automated applications that are established
                inside the blockchain. Blockchain is the present and the future
                of all escrow transactions. The smart contract solution which is
                Escrow just are the “third parties” . The process is more simple
                & secure. Terms are met before transactions & safely executed.
              </p>
            </div>
            <div className="col-12 col-md-4 text-center">
              <img src={blockc} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div class="row">
            <div className="col-12 col-md-12 text-center">
              <p className="center-text">
                HVAM adopts a decentralized method in Escrow services. HVAM will
                act as the neutral party of each transaction, it vanishes the
                need of a middleman or escrow agent, it will minimize the costs
                and time in executing the transaction. Users will be part of the
                venture by investing their funds on the platform. Funds/Assets
                will be stored in an escrow account until the conditions are
                fulfilled. Both parties involved in the agreement need to ensure
                that both the agreed service is delivered, and payment is made.
              </p>
              <img src={ecoservices} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div class="row">
            <div className="col-12 col-md-12">
              <h1 className="bottom">Guaranteed Standards:</h1>
              <p className="bottom">
                <b>Trust and security</b> – Smart contracts escrow are simply
                programs stored on a blockchain that run when predetermined
                conditions are met. Thus, it minimizes the risk of fraud by
                initiating as a neutral party and ensuring proper escrow
                transaction or execution.
              </p>
              <p className="bottom">
                <b>Liquidity</b> – Transparent transactions are a pleasant
                service. Transparency fosters trust and goodwill. Operations
                happening in Escrow for every transaction is transparent as it
                is accessible to all blockchain users.
              </p>
              <p className="bottom">
                <b>Cost-effective </b>– Blockchain banishes the need for third
                parties, which in turn helps to reduce the transaction cost and
                build up service competence.
              </p>
              <p className="bottom">
                <b>Fund-back protection</b> – Buyer receives the most protection
                in the Escrow process. In case the purchase isn’t met based on
                the agreement, the buyer is rest assured that the fund will be
                returned.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <h1 className=" bottom">HOW DOES ESCROW WORK?</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-9">
              <p>
                <b>1. Create an Escrow account.</b>
              </p>
              <p>
                The buyer inquires about the product or offering and starts to
                create an account in HVAM. Either the buyer or the seller can
                initiate the transaction. After setting up the account, terms
                and conditions of the contract have now to be expounded and
                agreed upon by both parties to start the transaction.
              </p>
            </div>
            <div className="col-12 col-md-3">
              <img src={secone} alt="" className="img-responsive" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-9">
              <p>
                <b>2. Funding HVAM account.</b>
              </p>
              <p>
                The buyer can now transfer funds (cryptocurrency) to the HVAM
                escrow account for safekeeping. It will automatically switch
                into a stable coin and will be stored in an escrow account. The
                cryptocurrency volatility is protected until the transaction is
                complete.
              </p>
            </div>
            <div className="col-12 col-md-3">
              <img src={sectwo} alt="" className="img-responsive" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-9">
              <p>
                <b>3. Delivery Confirmation.</b>
              </p>
              <p>
                The seller receives the notification, he may now deliver the
                product and send the necessary delivery information to the HVAM
                escrow service. Once the buyer confirms that the product has
                been delivered, the buyer now has a specific inspection period
                time to verify the product before the acceptance or rejection.A
                dispute resolution process can be set up on the platform if the
                buyer reject the product.
              </p>
            </div>
            <div className="col-12 col-md-3">
              <img src={secthree} alt="" className="img-responsive" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-9">
              <p>
                <b>4. HVAM releases the payment to the seller.</b>
              </p>
              <p>
                If the product meets the specifications and conditions agreed
                upon, HVAM may now release the funds to the seller accordingly.
              </p>
            </div>
            <div className="col-12 col-md-3">
              <img src={secfour} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div class="row">
            <div className="col-12 col-md-12">
              <p className="center-text">
                The diagram shows that a smart contract can play the role of an
                escrow that retains the funds until certain conditions are met.
                (1) Specify settlement conditions and procedures. (2) The third
                party or buyer transfers the token to the escrow smart contract.
                (3) Checking released conditions if desired products/services
                are met. (4) releases the token(s) to the first party or seller.
              </p>
              <br />
              <p className="center-text">ESCROW BLOCKCHAIN FLOWCHART</p>
              <img src={secfive} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <h1 className=" bottom color-org">HVAM Wallet</h1>
              <p>
                <b>Store all your assets in one place.</b>
              </p>

              <p>
                We developed our own wallet for you to store your cryptos and
                NFTs securely, initiate sending, receiving, exchange tokens,
                access our NFT Marketplace and payments. HVAM wallet is a
                self-custody wallet, in which you’re in full ownership of your
                assets, private keys, and data. Securing your assets with
                multi-sig.Optional key encryption employs several security
                mechanisms (secret phrase) to protect client’s assets against
                hacking or fraud. Log-in and manage your digital
                assets.Available for Android and soon iOS.
              </p>
              <ul className="wallet-ul">
                <li>Track charts, transactions, and prices within the App.</li>
                <li>Top-up prepaid card.</li>
                <li>
                  Buy, sell, store, and share your cryptos and NFT collections.
                </li>
                <li>Explore Decentralized Apps within the wallet.</li>
                <li>Easily swap multiple tokens.</li>
              </ul>
            </div>
            <div className="col-12 col-md-4">
              <img src={walletim} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <h1 className=" bottom color-org">HVAM Swap </h1>
              <h3 className="bottom">Exchange Digital Currencies Into HVAM</h3>

              <p>
                Swapping refers to exchanging a token to another one. Crypto
                swapping has become known in major platforms and is now
                mainstreaming as a selling point in exchanges. The process is
                already expeditious by design so competitive factors come down
                to variety and transaction fees. We designed our own Swapping,
                an integration in our wallet where participants can exchange
                digital currencies thanks to HVAM Swap, which is safer and 100%
                reliable.
              </p>
            </div>
            <div className="col-12 col-md-4">
              <img src={swapim} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <h1>NFT Platform</h1>
              <p className="bottom">
                <b>Create, sell, buy and advertise unique NFTs collections.</b>
              </p>
              <p>
                Non-fungible token is a financial security consisting of digital
                data stored in a blockchain. The ownership of an NFT is recorded
                in the blockchain, and can be transferred by the owner, allowing
                NFTs to be sold or traded. HVAM offers non-fungible token
                marketplace for users, investors, and holders to create, buy and
                advertise their unique NFTs collections.
              </p>
            </div>
            <div className="col-12 col-md-4">
              <img src={plateformim} alt="" className="img-responsive" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8">
              <h1>NFT Project</h1>
              <p>Exclusive access to our unique NFTs collections.</p>
            </div>
            <div className="col-12 col-md-4">
              <img src={projectim} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9">
              <h3 className="bottom color-org">
                Purpose Of HVAM Escrow Payments
              </h3>
              <p>
                The main purpose of HVAM escrow payment is to ensure the highest
                security from personal information to financial details in
                exchange/transactions. Trust is one of the major obstacles in
                Business. Scared to be scammed, scared to pay first before
                receiving the product, scared that the product isn’t meeting the
                requirements. HVAM escrow service solves this as a mediator. We
                keep the seller’s cryptos transaction in a safe place until the
                product, stocks, asset... is reviewed by the buyer. Once it's
                done and conditions are made, we release the payment.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3"></div>
            <div className="col-12 col-md-9 text-right">
              <h3 className="bottom align-rigt color-org">
                Why HVAM Choose Decentralized Escrow?
              </h3>
              <p className="align-rigt">
                HVAM platform provides the safest trading environment. Crypto
                Escrow is secured with your funds stored in cold storage, ( in
                physical isolation from IT systems) meaning you can be
                hacked.Our crypto escrow service is powered by blockchain,
                Information and funds are secured. Escrow payments made on
                blockchain have numerous advantages compared to the traditional
                Escrow system & funds are not in the hands of one single actor
                (centralized). Smart contracts allow that, they schedule
                transactions/actions to execute based on a series of
                transparent, predefined, and immutable conditions.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 dnone">
              <img src={teamim} alt="" className="img-responsive" />
            </div>
            <div className="col-12 col-md-8">
              <h1 className="bottom color-org">THE TEAM</h1>
              <p>
                A highly skilled and motivated team with great expertise in
                trading industry and businesses, allied by economic advisors
                focusing on market trends.
              </p>
            </div>
            <div className="col-12 col-md-4 dblock">
              <img src={teamim} alt="" className="img-responsive" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <img src={customerim} alt="" className="img-responsive" />
            </div>
            <div className="col-12 col-md-8">
              <h1 className="bottom sec4-right2-text color-org">
                CUSTOMER STATS{" "}
              </h1>
              <p className="sec4-right2-text">
                HVAM is targeting all generations : X, Y, Z We develop
                user-friendly technology for all Our platform is designed to be
                handled by any individual, professionals or simple buyer
                /seller.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 dnone">
              <img src={innovationim} alt="" className="img-responsive" />
            </div>
            <div className="col-12 col-md-8">
              <h1 className="bottom color-org">INNOVATION</h1>
              <p>
                HVAM developed a new trading website which is more creative and
                user friendly.. In the next three years, HVAM aims to enter a
                variety of successful platforms in the trading industry, Implant
                the company in the southern part of Europe and create more
                dynamic projects with vast benefits for our clients and
                investors. We aim to integrate more development, with future
                integrations for our platform, specifics and custom services
                with price appreciation.
              </p>
            </div>
            <div className="col-12 col-md-4 dblock">
              <img src={innovationim} alt="" className="img-responsive" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <img src={opportunityim} alt="" className="img-responsive" />
            </div>
            <div className="col-12 col-md-8">
              <h1 className="bottom sec4-right2-text color-org">OPPORTUNITY</h1>
              <p className="sec4-right2-text">
                Most influential blockchain-based company. We modernize
                traditional functions of financial services by empowering
                cryptocurrency. HVAM is developing an open-source, restrictions,
                and transparent financial service ecosystem. You always have the
                full control of your assets thanks to your HVAM wallet & key.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9">
              <h1 className="bottom color-org">DEVELOPING HVAM WEBSITE</h1>
              <p>
                Let’s face it! Later on, website development services changed
                immensely how the business industry works. Developing the right
                website can increase sales by targeting more clients, boost your
                brand awareness. We, daily, develop our website platform to meet
                customer satisfaction requirements and to make it the most user
                friendly possible.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3">
              <img src={milestoneim} alt="" className="img-responsive" />
            </div>
            <div className="col-12 col-md-9">
              <h1 className="bottom sec4-right2-text color-org">MILESTONE</h1>
              <p className="sec4-right2-text">
                Created an innovative website to communicate with the public.
                Successfully invited close investors to support the project,
                designing a potent technological progress, provide the best
                service to users, expansion of workplaces. In the next 3 years,
                HVAM aims to diversify.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 text-center">
              <h1 className="bottom color-org">LEGITIMACY</h1>
              <p>
                Certified as a private company registered and developed in
                England and Wales. HVAM attains certificates that attest the
                legitimacy of the company.
              </p>
              <br />
              <img src={legitimacyim} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <h1 className="bottom color-org">
                Adaptation Of Blockchain: Letter Of Credit
              </h1>
              <p>
                A letter of credit is considered as the main instrument in
                international trade. It is a contract between a bank, bank’s
                customer, and beneficiary. Mainly, it is the importer’s bank who
                will issue the LC to guarantee that the beneficiary will be paid
                once the conditions of the LC are met. A classic LC version is
                complex, usually the process is time-consuming, and high cost.
                Aside from that, according to the survey (B. Consulting Group,
                2017) it is very rare that the goods/delivery arrives at
                destination, necessary documents would take a long time before
                it will get verified by the bank and only 1% of the transactions
                between each participant out of thousands creates value.
              </p>
              <br />
              <p>
                The acquisition of blockchain technology in terms of LC is a big
                advantage for each trader. The letter of credit is issued on a
                distributed ledger technology network involving exporter, banks,
                importer, and other components acting as participating nodes.
                Blockchain technology in LC will simplify the process of each
                transaction by removing the risk of altered documents, it will
                also reduce fees of each transaction. Trade procedures can
                automatically be done through a sequence of digital smart
                contracts that is self-executed once certain conditions are met.
                LC powered by blockchain proves to be more efficient. The whole
                transaction process is more secure, fast, and cost-effective.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <h1 className="bottom color-org">
                Advantages Of Blockchain Technology In Letter Of Credit:
              </h1>
              <ul>
                <li>
                  Blockchain technology in LC vanishes the need for “physical
                  presentation” of documents etc. Boosting your time.
                </li>
                <li>
                  Each payment transaction globally is more: efficient, trusted,
                  effective, and easier for all parties in a letter of credit.
                </li>
                <li>
                  Reduce the tendencies of altered documents & transaction fees.
                </li>
                <li>
                  Automated payments processes on blockchain are fast and
                  secure. It will minimize any disputes and payment delays due
                  to any discrepancies from all the parties.
                </li>
                <li>Blockchain eliminates the necessity of banks.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 text-center">
              <h1 className="bottom color-org">
                Advantages Of Blockchain Technology In Letter Of Credit:
              </h1>
              <img src={creditim} alt="" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <h2 className="bottom color-org">UNDERSTANDING KYC & AML</h2>
            </div>
            <div className="col-12 col-md-6">
              <p>
                KYC and AML procedures are expanding to carry on with the
                increasing demand for financial services and the drastic need
                for increased security and protection against criminal elements
                such as money laundering and terrorism financing.
              </p>
              <br />
              <p>
                ‘Know Your Customer’ and ‘Anti-Money Laundering’, KYC and AML
                submissions are required by law, to alleviate the risks of
                companies for financial fraud. It contains various verification,
                observing, and corroborate procedures, these policies need to be
                unassailable to prevent malicious actions. If not, these
                entities face high non-compliance fines from regulatory bodies.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <p>
                HVAM has strictly adopted KYC procedures to corroborate or
                verify the identity of every user that is trying to participate
                in HVAM. Issuer understands the essence of preventing any fraud
                and financial crime such money laundering and terrorism
                financing. An anti-money laundering act was established to avoid
                money laundering that finances terrorism. Pursuant to this Act,
                financial institutions must report suspicious transactions or
                unwonted activities, but not report this - whether intentionally
                or not - and may be allowed. The United States Department of the
                Treasury is well committed to restraining all sides of fraud at
                home and abroad, through the mission of the Office of Terrorism
                and Financial Intelligence (TFI).
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-3">
              <h1 className="color-org">DISCLAIMER</h1>
            </div>
            <div className="col-12 col-md-9">
              <p className="sixee">
                The Legal Disclaimer section must be carefully read. WE
                recommend you consult a legal, taxation, financial, entity and
                professional law advisor(s) for guidance prior to participating
                in HVAM activities.
              </p>
            </div>
            <div className="col-12 col-md-12">
              <p>
                The information in this website including texts, graphics,
                images etc. are for information purposes, does not constitute an
                offer of financial instrument, securities, are not registered
                with any government entity as a security and shall not be
                considered as such. This legal disclaimer applies to whitepaper
                and/or any other information/documents available on the website.
                the legal disclaimer and other information available on the
                website may be updated from time to time and be published as the
                latest version of Whitepaper. The information set forth in this
                Legal disclaimer section may not be complete and does not infer
                any kind of a contractual alliance. All information are accurate
                and up to date, such material in no way constitutes professional
                advice.
              </p>
              <br />
              <p>
                Whitepaper and any other information including texts, graphic,
                images and/or any documents indicated on our website shall not
                be considered to be any sort of solicitation for investment, nor
                business, legal or tax advice regarding the company, or any of
                the matters related to any part of the available information.
                Consulting your legal, financial or tax advisor is a must prior
                to participating in the company.
              </p>
              <br />
              <p>
                It is entirely up to you, to prior or ensuing approval
                registration, license if needed or required. It is entirely up
                to you, to acquire/ ensuing approval, registration / license or
                to require any legal form document in the country where you are
                a citizen, national, resident. Having a similar connecting
                factor, or incorporated, registered or effectively managed. The
                Issuer, shall not in any way be liable for any non-conformity
                terms above, by you.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <h1 className="color-org bottom">Social Media</h1>
              <div className="color-org">
                <img
                  src={facebookim}
                  className="faceook-icon width-all"
                  alt=""
                />
                <b>Facebook: /hvambit</b>
              </div>
              <div className="color-org">
                <img src={instaim} className="faceook-icon width-all" alt="" />
                <b>Instagram: /hvambit/</b>
              </div>
              <div className="color-org">
                <img src={tiktokim} className="faceook-icon width-all" alt="" />
                <b>Tiktok: /@hvambit</b>
              </div>
              <div className="color-org">
                <img
                  src={whatsappim}
                  className="faceook-icon width-all"
                  alt=""
                />
                <b>+44 7488 8808 02</b>
              </div>
              <div className="color-org">
                <img src={emailim} className="faceook-icon width-all" alt="" />
                <b>Email: info@hvambit.com </b>
              </div>
              <div className="color-org">
                <img
                  src={websiteim}
                  className="faceook-icon width-all"
                  alt=""
                />
                <b>WWW.HVAMBIT.COM</b>
              </div>
            </div>
            <div className="col-12 col-md-6 pt30">
              <img src={lastim} className="img-responsive" alt="" />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Whitepaper;
