import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

const Shop = () => {
  return (
    <>
      <Header/>
      <section id="hero" className="pt80 bg-dark heromian"></section>
      <section className="pt150 pb150 bg-yelowes">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 text-center">
              <h1 className="h1-lg mb10 text-uppercase">Coming Soon...</h1>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  )
}

export default Shop
